import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#6BFFCD',
      main: '#007D74',
      dark: '#2ED1B6',
      contrastText: '#fff'
    },
    secondary: {
      light: '#9578FF',
      main: '#5F41CC',
      dark: '#402E7D',
      contrastText: '#fff'
    },
    brandPastel: {
      one: '#FFB0B0',
      two: '#FFD4AD',
      three: '#FFEEA3',
      four: '#CCFFA3',
      five: '#B0FFCF',
      six: '#A8FFFC',
      seven: '#ADDFFF',
      eight: '#C7B8FF',
      nine: '#E9B6FF',
      ten: '#FFB3D7'
    },
    brandLight: {
      one: '#FF7373',
      two: '#FFA95E',
      three: '#FFF763',
      four: '#9FF062',
      five: '#00DB99',
      six: '#6BF3FF',
      seven: '#4DB8FF',
      eight: '#9578FF',
      nine: '#DA6BF9',
      ten: '#FF63AE'
    },
    brand: {
      one: '#CF4242',
      two: '#D9853B',
      three: '#EBD926',
      four: '#4DB531',
      five: '#00AB97',
      six: '#3DC2D1',
      seven: '#1877B8',
      eight: '#5F41CC',
      nine: '#9C30CA',
      ten: '#D63280'
    },
    brandDark: {
      one: '#942525',
      two: '#A34B00',
      three: '#C7A643',
      four: '#008213',
      five: '#008071',
      six: '#006D7D',
      seven: '#004B7D',
      eight: '#402E7D',
      nine: '#7E208F',
      ten: '#8F144F'
    },
    two:{
      light: '#FFD4AD',
      main: '#D9853B',
      dark: '#A34B00'
    },
    one_two:{
      start: 'linear-gradient(-45deg, #FF737305, #FFAD6605)',
      mid: 'linear-gradient(-45deg, #FF737320, #FFAD6620)',
      end: 'linear-gradient(-45deg, #FF737380, #FFAD6680)',
      full: 'linear-gradient(-45deg, #FF7373, #FFAD66)',
    },
    gradient1:{
      start: 'linear-gradient(-45deg, #0f00f005, #ff0ff005)',
      mid: 'linear-gradient(-45deg, #0f00f020, #ff0ff020)',
      end: 'linear-gradient(-45deg, #0f00f080, #ff0ff080)',
    },
    gradient3:{
      start: 'linear-gradient(-45deg, #B4FF7A15, #5CFF9B15, #6BD9FF15)',
      mid: 'linear-gradient(-45deg, #B4FF7A40, #5CFF9B40, #6BD9FF40)',
      end: 'linear-gradient(-45deg, #B4FF7A80, #5CFF9B80, #6BD9FF80)',
    },
    gradient2:{
      start: 'linear-gradient(-45deg, #9578FF15, #FF63AE15)',
      mid: 'linear-gradient(-45deg, #9578FF40, #FF63AE40)',
      end: 'linear-gradient(-45deg, #9578FF80, #FF63AE80)',
    },
    tertiary: {
      main: '#F2B16B',
      dark: '#CC8229',
      light: '#E5B076',
      contrastText: '#fff'
    },
    selected: {
      light: '#71CEDB',
      main: '#3A80AF',
      dark: '#00557F',
      contrastText: '#fff'
    },
    primarySub: {
      main: '#39B58A',
      dark: '#6FDD9B',
      contrastText: '#fff'
    },
    course:{
      main: '#008071',
      dark: '',
      contrastText: '#fff'
    },
    assignment:{
      light: '#00DB99',
      main: '#00AB97',
      dark: '#008071',
      contrastText: '#fff'
    },
    opinion:{
      pastel: '#C7B8FF',
      light: '#9578FF',
      main: '#5F41CC',
      dark: '#402E7D',
      contrastText: '#fff'
    },
    response:{
      pastel: '#E9B6FF',
      light: '#DA6BF9',
      main: '#9C30CA',
      dark: '#7E208F',
      contrastText: '#fff'
    },
    reply:{
      pastel: '#FFB3D7',
      light: '#FF63AE',
      main: '#D63280',
      dark: '#8F144F',
      contrastText: '#fff'
    },
    accent: {
      yellow: '#E5FF00',
      green: '#C1FF5C'
    },
    green: {
      dark: '#008071',
      mid: '#39B58A',
      light: '#6FDD9B'
    },
    tone: {
      main: '#dddddddd',
      dark: '#aaaaaaaa',
      light: '#eeeeeeee'
    },
    dark: {
      main: '#000',
      dark: '#000444',
      light: '#eee',
      contrastText: '#fff'
    },
    light: {
      main: '#fff'
    },
    background: {
      main: '#F6F6F6',
      side: '#FFFFFF',
      light: '#FFFFFF'
    }
  },
  typography: {
    fontFamily: 'Poppins',
    lineHeight: 1,
    editor: {
      h1: {
        fontFamily:'Poppins',
        fontSize: '1.6rem',
        fontWeight:600,
        lineHeight:1.2,
        color: '#666'
      },
      h2: {
        fontFamily:'Poppins',
        fontSize: '1.4rem',
        fontWeight:600,
        lineHeight:1.3,
        color: '#666'
      },
      body: {
        fontFamily:'Poppins',
        fontSize: '1.2rem',
        textAlign:'left',
        lineHeight:1.4,
        color: '#666'
      },
    },
    h1: {
      fontSize:160
    },
    h2: {
      fontFamily:'Poppins',
      fontSize:90,
      fontWeight:'700',
      lineHeight:.9,
      textTransform:'uppercase',
      color:'#008071'
    },
    h4: {
      fontFamily:'Poppins',
      fontSize:'24px',
      fontWeight:500,
      lineHeight: 1,
      color:'#555',
      textTransform:'capitalize'
    },
    h5: {
      fontFamily: 'Manrope',
      fontSize: '26px',
      fontWeight:300,
      lineHeight:1.4,
      color: '#666'
    },
    h6: {
      lineHeight: 1.1
    },
    body1: {
      // fontFamily: 'Manrope',
      fontSize: 18,
      // fontWeight:400,
      // lineHeight:1.2,
      color: '#666'
    },
    body2: {
      // fontFamily: 'Manrope',
      // fontSize: '16px',
      // fontWeight:400,
      // lineHeight:1.4,
      color: '#666'
    },
    sub: {
      fontFamily: 'Poppins',
      fontSize: 12,
      fontWeight:400,
      lineHeight:1.2,
      textTransform:'uppercase',
      color: '#666'
    },
    errorMessage: {
      fontFamily: 'Manrope',
      fontSize: 16,
      fontWeight:400,
      lineHeight:1,
      color: 'red'
    },
    tag: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 400,
      color: '#fff'
    },
    button: {
      lineHeight: .9,
      letterSpacing:1
    },
    MuiTab: {
      fontFamily: 'Poppins'
    }
  },
  components: {
    MuiButton: {
      style: {
        letterSpacing:3
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            padding: '10px 20px 10px 20px',
            letterSpacing:1.5
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            padding: '10px 20px 10px 20px',
            letterSpacing:.8
          },
        },
      ],
    },
  },

})

export const themeLight = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    // background:{
    //   main:''
    // }
  },
})

export const themeDark = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    background:{
      main:'#26003E',
      side:'#26003E'
    }
  },
})