import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { useAuth } from '../../context/authContext';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { ButtonCust } from '../ui/Buttons';
import { gradientCustom } from '../../lib/Styles';
import { useTheme } from '@emotion/react';
import { useGetUsers } from '../../hooks/mutations';


const ModalDemoLogin = (props) => {
  const { title, content, setAuthenticated, bgColor, open, handleClose } = props;

  const [loginEmail, setLoginEmail] = useState();
  const [loginPassword, setLoginPassword] = useState();
  const [reset, setReset] = useState();
  const [user, setUser] = useState({});
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const users = useGetUsers();
  console.log('users', users);

  const auth = getAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  // START LOGIN FUNCTION
  const authContext = useAuth();

  useEffect(() => {
    if (users) {
      const teachArr = users.filter(x => x.type === 'teacher');
      const studeArr = users.filter(x => x.type === 'student');

      // Sorting the array by the 'firstName' field
      teachArr.sort((a, b) => {
        const firstNameA = a.firstName.toLowerCase();
        const firstNameB = b.firstName.toLowerCase();

        if (firstNameA < firstNameB) {
          return -1;
        }
        if (firstNameA > firstNameB) {
          return 1;
        }
        return 0;
      });

      studeArr.sort((a, b) => {
        const firstNameA = a.firstName.toLowerCase();
        const firstNameB = b.firstName.toLowerCase();

        if (firstNameA < firstNameB) {
          return -1;
        }
        if (firstNameA > firstNameB) {
          return 1;
        }
        return 0;
      });

      setTeachers(teachArr);
      setStudents(studeArr);
    }
  }, [users])

  useEffect(() => {
    if (authContext.account) {
      navigate('/dashboard');
      handleClose();
    }

  }, [authContext.account])

  const loginUser = async () => {
    try {
      authContext.loginUser(loginEmail, loginPassword);
      authContext.getAccount();
      setLoginEmail();
      setLoginPassword();
      
    } catch (error) {
      console.log('Error logging in user');
    }
  }

  const autoLoginUser = async (email, password) => {
    authContext.loginUser(email, password);
    authContext.getAccount();
    setLoginEmail();
    setLoginPassword();
  }

  const recoverPassword = () => {
    try {
      return sendPasswordResetEmail(auth, loginEmail);
    } catch (error) {
      console.log('Error resetting password');
    }
  }


  const handlePasswordReset = () => {
    setReset(!reset);
  }

  const studentLogin = (student, password) => {
    setLoginEmail(student);
    setLoginPassword(password);
  }

  const LoginButton = (props) => {
    const { email, name, type } = props;

    return (
      <Button
        onClick={() => autoLoginUser(email, 'opineschool')}
        color={type === "student" ? 'primary' : 'secondary'}
        fullWidth
        sx={{
          px:1,
          py:1,
          borderRadius:2,
          fontSize:12,
        }}
      >
        {name}
      </Button>
    )
  }

  // !!COPY INTO PARENT COMPONENT!!
  // const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropProps={{
        sx:{
          background: 
            gradientCustom({
              theme:theme, 
              type1:'light',
              type2:' ',
              color1:'nine',
              color2:'eight',
              deg:'135deg',
              opacity:'f8'
            })
        }
      }}
      PaperProps={{
        style: {
          backgroundColor: bgColor,
          boxShadow:'none'
        }
      }}
    >
      <DialogTitle textAlign="center">{title}</DialogTitle>
      <DialogContent>
        <Grid
          container
          sx={{
            justifyContent:'center',
          }}
          xs={12}
        >
          <Grid
            container
            justifyContent="center"
            sx={{
              gap:1,
              p:3,
            }}
          >
            <Grid
              item
              xs={12}
            >
              <Grid
                container
                justifyContent="space-between"mb={1}
              >
                <Typography sx={{ml:3, mb:.5, fontSize:12, fontWeight:600, backgroundColor:theme.palette.brandDark.eight, color:'#fff', px:2, borderRadius:1}}>TEACHERS</Typography>
                <Grid
                  container
                  sx={{
                    width:'100%',
                    border:`1px solid ${theme.palette.brandDark.eight}`,
                    borderRadius:'16px',
                    p:1,
                    mb:1
                  }}
                >
                  {
                    teachers?.map((user, index) => (
                      <Grid
                        item
                        sx={{
                          px:1,
                          width:'auto'
                        }}
                        // xs={12}
                        // sm={6}
                        // md={4}
                      >
                        <LoginButton type={user.type} email={user.email} name={`${user.firstName} ${user.lastName}`} /> 
                      </Grid>
                    ))
                  }
                </Grid>

              </Grid>
              <Grid
                container
                justifyContent="space-between"mb={1}
              >
                <Typography sx={{ml:3, mb:.5, fontSize:12, fontWeight:600, backgroundColor:theme.palette.brandDark.five, color:'#fff', px:2, borderRadius:1}}>STUDENTS</Typography>
                <Grid
                  container
                  sx={{
                    width:'100%',
                    border:`1px solid ${theme.palette.brandDark.five}`,
                    borderRadius:'16px',
                    p:1,
                    mb:1
                  }}
                >
                  {
                    students?.map((user, index) => (
                      <Grid
                        item
                        sx={{
                          px:1,
                          width:'auto'
                        }}
                        // xs={12}
                        // sm={6}
                        // md={4}
                      >
                        <LoginButton type={user.type} email={user.email} name={`${user.firstName} ${user.lastName}`} /> 
                      </Grid>
                    ))
                  }
                </Grid>

              </Grid>
              <TextField
                label="Email"
                autoFocus
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={loginEmail}
                onChange={(e) => setLoginEmail(e.target.value)}
              />
            </Grid>
            {!reset ? (
              <Grid
                item
                xs={12}
              >
                <TextField
                  label="Password"
                  type="password"
                  fullWidth
                  sx={{
                    mt:1
                  }}
                  InputLabelProps={{ shrink: true }}
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                />
              </Grid>

            ) : (
              <Typography>Enter your email and we will send you a recovery email if your email has an account at Opine.</Typography>
            )}
            <Grid
              item
              xs={12}
            >
              <ButtonCust
                onClick={reset ? recoverPassword : loginUser}
                buttonProps={{
                  fullWidth:true
                }}
                variant="contained"
                color1Start="primary.main"
                color1End="primary.main"
                color2Start="primary.dark"
                color2End="primary.main"
                angle="135deg"
                sx={{
                  color:'#fff',
                  mt:2
                }}
              >
                {reset ? 'Reset password' : 'Login'}
              </ButtonCust>

            </Grid>
            <FormControlLabel
              value="end"
              control={<Switch checked={reset} onChange={handlePasswordReset} color="primary" />}
              label="I forget my password"
              labelPlacement="end"
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ModalDemoLogin
