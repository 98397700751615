import React, { useEffect, useState } from 'react'
import { auth, db } from '../../firebase';
import { addDoc, arrayUnion, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged, updateProfile } from 'firebase/auth';
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import * as yup from 'yup';
import { makeStyles } from '@mui/styles';
import { useAuth } from '../../context/authContext';
import AlertsTemplate from '../alerts/AlertsTemplate';
import { Portal } from '@mui/base';
import { Close } from '@mui/icons-material';
import { gradientCustom } from '../../lib/Styles';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { ButtonCust } from '../ui/Buttons';


// Added Styles
const useStyles = makeStyles(({ breakpoints }) => ({
  button1: {
    borderWidth:3,
      '&:hover': {
        color:'#fff',
        fontSize:'18px',
        backgroundColor:'#41aee095',
        borderWidth:3
      }
  },
  button2: {
    borderWidth:3,
      '&:hover': {
        color:'#fff',
        fontSize:'18px',
        backgroundColor:'#9d84b795',
        borderWidth:3
      }
  }
}));


const ModalRegister = (props) => {
  const { title, content, setAuthenticated, bgColor, open, handleClose } = props;
  const [registerNew, setRegisterNew] = useState({
    firstName: '',
    lastName: '',
    displayName: '',
  });
  const [registerEmail, setRegisterEmail] = useState();
  const [registerPassword, setRegisterPassword] = useState();
  const [registerUsername, setRegisterUsername] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [user, setUser] = useState({});
  const [isAccredited, setIsAccredited] = useState(null);
  const [correctDomain, setCorrectDomain] = useState();
  const classes = useStyles();

  const [signup, setSignup] = useState({
    name: '',
    email: ''
  });


  const auth = getAuth();
  const authContext = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    setIsAccredited(null);
    setRegisterNew({name:'', email:'', displayName:''});
    setRegisterEmail();
    setRegisterUsername();
    setRegisterPassword();
    setConfirmPassword();
  }, [open])

  // START REGISTRATION FUNCTION
  const getOnList = async () => {
    try {
        // Create Account doc for new user with same user id and save to "users" collection
        const userRef = doc(db, 'signups');
        const userPayload = {
          email: signup.email || '',
          name: signup.name || '',
          createdAt: serverTimestamp()
        }

        await addDoc(userRef, userPayload);

        handleClose();
        setSignup();

    } catch (error) {
      registerError(error.code);
      console.log('Error getting on list', error);
    }
  }

  // START REGISTRATION FUNCTION
  const registerUser = async () => {
    try {
      // Split the registerEmail state to only have the domain to test in the next line
      const testDomain = registerEmail?.split('@')[1]
      // Test if the entered email matches the necessary school domain

      // Register user in Authentication database
        const newUser = await createUserWithEmailAndPassword(auth, registerEmail, registerPassword);
        setUser(newUser);

        // Add display name to Authentication database
        updateProfile(auth.currentUser, {
          displayName: registerUsername
        })

        // Create Account doc for new user with same user id and save to "users" collection
        const userRef = doc(db, 'users', newUser.user.uid );
        const userPayload = {
          id: newUser.user.uid,
          active: true,
          displayName: registerUsername || '',
          firstName: registerNew.firstName || '',
          lastName: registerNew.lastName || '',
          type: isTeacher ? 'teacher' : 'student',
          domain: testDomain || '',
          email: registerEmail || null,
          // userRole: arrayUnion('free'),
          // userType: arrayUnion(isAccredited ? 'investor' : 'founder'),
          createdAt: serverTimestamp()
        }

        await setDoc(userRef, userPayload);

        // Add user to "teachers" collection if isTeacher is true
        if (isTeacher) {
          // Create Account doc for new user with same user id and save to "teachers" collection
          const teacherRef = doc(db, 'teachers', newUser.user.uid );
          const teacherPayload = {
            id: newUser.user.uid,
            active: true,
            displayName: registerUsername || '',
            firstName: registerNew.firstName || '',
            lastName: registerNew.lastName || '',
            type: isTeacher ? 'teacher' : 'student',
            domain: testDomain || '',
            email: registerEmail || null,
            // userRole: arrayUnion('free'),
            // userType: arrayUnion(isAccredited ? 'investor' : 'founder'),
            createdAt: serverTimestamp()
          }

          await setDoc(teacherRef, teacherPayload);
        }

        setRegisterEmail();
        setRegisterUsername();
        setRegisterPassword();
        setConfirmPassword();
        setRegisterNew({});
        setIsTeacher(false);
        navigate('/dashboard');
        handleClose();

    } catch (error) {
      registerError(error.code);
      console.log('Error creating user', error);
    }
  }

  // START ERROR FUNCTION
  const registerError = (authCode) => {
    switch (authCode) {
      case "auth/email-already-in-use":
        return (authContext.handleAlert("That email is already registered to a user"))

      case "auth/invalid-email":
        return (authContext.handleAlert('That email is invalid'))

      case "auth/operation-not-allowed":
        return (authContext.handleAlert("There was an error registering"))

        case "auth/weak-password":
          return (authContext.handleAlert("Your password needs to be stronger"))

        case "auth/missing-email":
          return (authContext.handleAlert("You didn't enter an email"))

        default:
        return (authContext.handleAlert("There was an error registering"))
    }
  }
  // END REGISTRATION FUNCTION

  // YUP SETUP
  const yupSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    userName: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required()
  })

  
  // ALERTS
  // const [goTime, setGoTime] = useState(false);
  // const [notifyContent, setNotifyContent] = useState();
  // const handleNotification = (content) => {
  //   setNotifyContent(content);
  //   setGoTime(true);
  //   setTimeout(() => {setGoTime(false)}, 5000);
  // };
  // const handleStop = () => {
  //   setGoTime(false);
  // };

  // VERIFICATIONS
  const [openVerification, setOpenVerification] = useState(false);
  const [verifyContent, setVerifyContent] = useState();
  const [verifySeverity, setVerifySeverity] = useState();


  // UPDATE USER STATE FROM authContext
  const initializeAccount = async () => {
    await registerUser();
    if(auth.currentUser) {
      authContext.getAccount(auth.currentUser.uid);
    } else {
      console.log('user registration failed')
    }
  }


  // SET AUTH STATE CHANGE FUNCTIONALITY
  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
    authContext.getAccount();
  });
  //END AUTH STATE CHANGE FUNCTIONALITY

  // IS TEACHER FUNCTIONALITY
  const [isTeacher, setIsTeacher] = useState(true);
  const handleIsTeacher = (e) => {
    setIsTeacher(e.target.checked);
  }

  // SHOW PASSWORD FUNCTIONALITY
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = (e) => {
    setShowPassword(e.target.checked);
  }
  
  // !!COPY INTO PARENT COMPONENT FOR MODAL FUNCTIONALITY!!
  // const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  // !!END MODAL FUNCTIONALITY!!


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropProps={{
        sx:{
          background: 
            gradientCustom({
              theme:theme, 
              type1:'light',
              type2:' ',
              color1:'five',
              color2:'five',
              deg:'135deg',
              opacity:'f8'
            }),

          // gradientOne(theme, '', 'f8'),
          // backgroundColor:'background.main'
        }
      }}
      PaperProps={{
        style: {
          backgroundColor: bgColor,
          boxShadow:'none'
        }
      }}
    >
      <DialogTitle textAlign="center">{title}</DialogTitle>
        <DialogContent>
          <Grid
            container
            sx={{
              justifyContent:'center',
            }}
            xs={12}
          >
            <Grid
              container
              justifyContent="center"
              sx={{
                gap:1,
                p:3,
                pt:0
              }}
              xs={12}
            >
              <Grid
                item
                sx={{
                  display:'flex',
                  justifyContent:'center'
                }}
                xs={12}
              >
                <FormControlLabel
                  value={isTeacher}
                  control={<Checkbox checked={isTeacher} onChange={(e) => handleIsTeacher(e)} />}
                  label="I am a teacher"
                  labelPlacement="end"
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <TextField
                  // disabled
                  label="First Name"
                  variant="standard"
                  fullWidth
                  value={registerNew?.firstName}
                  onChange={(e) => setRegisterNew({...registerNew, firstName: e.target.value})}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  // disabled
                  label="Last Name"
                  variant="standard"
                  fullWidth
                  value={registerNew?.lastName}
                  onChange={(e) => setRegisterNew({...registerNew, lastName: e.target.value})}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  // disabled
                  label="School Email"
                  variant="standard"
                  fullWidth
                  value={registerEmail}
                  onChange={(e) => setRegisterEmail(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  // disabled
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  variant="standard"
                  fullWidth
                  value={registerPassword}
                  onChange={(e) => setRegisterPassword(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  // disabled
                  label="Confirm Password"
                  type={showPassword ? 'text' : 'password'}
                  variant="standard"
                  fullWidth
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display:'flex',
                  justifyContent:'center'
                }}
                xs={12}
              >
                <FormControlLabel
                  value={showPassword}
                  control={<Checkbox checked={showPassword} onChange={(e) => handleShowPassword(e)} />}
                  label="Show Password"
                  labelPlacement="end"
                />
              </Grid>
              {/* <FormControlLabel
                value={isTeacher}
                control={<Checkbox checked={isTeacher} onChange={(e) => handleIsTeacher(e)} />}
                label="I am a teacher"
                labelPlacement="end"
              /> */}
              <Collapse in={openVerification}>
                <Alert
                  severity={verifySeverity}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenVerification(false);
                      }}
                    >
                      <Close fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mt:1, mb: 2 }}
                >
                  {verifyContent}
                </Alert>
              </Collapse>

              <Grid
                item
                xs={12}
              >
                <ButtonCust
                  onClick={initializeAccount}
                  buttonProps={{
                    disabled:registerNew?.firstName && registerNew?.lastName && registerPassword && registerPassword === confirmPassword ? false : true,
                    fullWidth:true
                  }}
                  variant="contained"
                  color1Start="brand.nine"
                  color1End="brand.eight"
                  color2Start="brand.ten"
                  color2End="brand.nine"
                  angle="135deg"
                  sx={{
                    color:'#fff',
                    mt:2
                  }}
                >
                  {registerNew.firstName && registerNew.lastName && registerPassword && registerPassword === confirmPassword ? 'Register' : 'Fill out all fields'} 
                </ButtonCust>

                {/* <Button
                  variant="contained"
                  color="secondary"
                  disabled={registerNew.firstName && registerNew.lastName && registerPassword && registerPassword === confirmPassword ? false : true}
                  fullWidth
                  sx={{
                    p:2,
                    mt:2
                  }}
                  onClick={initializeAccount}
                >
                  Register
                </Button> */}
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid
            container
          >
            <Grid
              item
              mb={2}
              xs={12}
            >
            <Typography>
              Registration is currently closed. Check back soon or leave your email below if you would like someone to reach out to you. We are currently accepting beta users for the 2023 school year.
            </Typography>
            </Grid>
            <Grid
                item
                mb={2}
                xs={12}
              >
                <TextField
                  // disabled
                  label="Name"
                  variant="standard"
                  fullWidth
                  value={signup.name}
                  onChange={(e) => setSignup({...signup, name: e.target.value})}
                />
              </Grid>
              <Grid
                item
                mb={2}
                xs={12}
              >
                <TextField
                  // disabled
                  label="Email"
                  variant="standard"
                  type="email"
                  fullWidth
                  value={signup.email}
                  onChange={(e) => setSignup({...signup, email: e.target.value})}
                />
              </Grid>

              <Button variant="contained" color="secondary" fullWidth sx={{p:2, mt:2}} onClick={getOnList}>
                Get on the list
              </Button>
          </Grid> */}

        </DialogContent>

      {/* ALERTS */}
      {/* <Portal>
        <AlertsTemplate
          content={notifyContent}
          color="#9B30F9"
          goTime={goTime}
          setGoTime={setGoTime}
          handleNotification={handleNotification}
          handleStop={handleStop}
        />
      </Portal> */}

    </Dialog>
  );
}

export default ModalRegister
