import { useTheme } from "@emotion/react";
import { VerticalSplit, ViewHeadline } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { gradientCustom } from "../../lib/Styles";
import InfoModalTemplate from "./InfoModalTemplate";
import TooltipCustom from "./TooltipCustom";
import TooltipTemplate from "./TooltipTemplate";

export const ButtonCust = (props) => {
  const { color1Start, color1End, color2Start, color2End, angle, children, sx, buttonProps, disabledStart, disabledEnd, disabledAngle, onClick } = props;
  const theme = useTheme();

  const bgColor1Start = color1Start ? eval(`theme.palette.${color1Start}`) : theme.palette.brand.one;
  const bgColor1End = color1End ? eval(`theme.palette.${color1End}`) : theme.palette.brand.two;
  const bgColor2Start = color2Start ? eval(`theme.palette.${color2Start}`) : theme.palette.brand.nine;
  const bgColor2End = color2End ? eval(`theme.palette.${color2End}`) : theme.palette.brand.one;
  const deg = angle ? angle : '-45deg';

  const bgDisabledStart = disabledStart ? eval(`theme.palette.${disabledStart}`) : theme.palette.brand.one;
  const bgDisabledEnd = disabledEnd ? eval(`theme.palette.${disabledEnd}`) : theme.palette.brand.two;
  const disabledDeg = disabledAngle ? disabledAngle : '-45deg';

  return (
    <Button
      {...buttonProps}
      onClick={onClick}
      sx={{
        ...sx,
        position:'relative',
        color: buttonProps?.disabled ?
            '#fff !important'
          :
            '#fff'
        ,
        background: buttonProps?.disabled 
          ?
          disabledStart
            ?
              `linear-gradient(${disabledDeg}, ${bgDisabledStart}, ${bgDisabledEnd})`
            :
              gradientCustom({
                theme:theme, 
                type1:'light',
                type2:' ',
                color1:'two',
                color2:'one',
                deg:'135deg',
                opacity:'f8'
              })
          :
            `linear-gradient(${deg}, ${bgColor1Start}, ${bgColor1End})`,
        // color:'#fff',
        px:sx?.px ? sx.px : 2.5,
        py:sx?.py ? sx.py : 2,
        overflow:'hidden',
        zIndex:1,

        '&::before':{
          content: `''`,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0,
          zIndex: -1,
          background:`linear-gradient(${deg}, ${bgColor2Start}, ${bgColor2End})`,
          transition: 'opacity .5s',
        },

        '&&:hover':{
          '&::before':{
            opacity:1
          }
        }
      
      }}
    >
      {children}
    </Button>
  )
}

export const Button1 = (props) => {
  const { color1Start, color1End, color2Start, color2End, angle, children, sx, buttonProps } = props;
  const theme = useTheme();

  const bgColor1Start = theme.palette.brand.nine;
  const bgColor1Mid = theme.palette.brand.one;
  const bgColor1End = theme.palette.brand.two;
  const bgColor2Start = theme.palette.brand.two;
  const bgColor2Mid = theme.palette.brand.two;
  const bgColor2End = theme.palette.brand.one;
  const deg = '-45deg';

  return (
    <Button
      {...buttonProps}
      sx={{
        position:'relative',
        background: `linear-gradient(${deg}, ${bgColor2Start}, ${bgColor2Mid}, ${bgColor2End})`,
        zIndex:1,

        '&::before':{
          content: `''`,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0,
          zIndex: -1,
          background:`linear-gradient(${deg}, ${bgColor2Start}, ${bgColor2End})`,
          transition: 'opacity .5s',
        },

        '&&:hover':{
          '&::before':{
            opacity:1
          }
        }
      
      }}
    >
      {children}
    </Button>
  )
}

export const SubmissionButton = (props) => {
  const { color, dialog, infoSplit, infoSingle, infoModal, link, linkSingle, text, limitReached  } = props;
  const theme = useTheme();

  return (
    limitReached
      ?
        <Typography>Limit Reached</Typography>
      :
        <Grid container
          sx={{
            justifyContent:'center',
            background:`${theme.palette[color].pastel}aa`,
          }}
        >
          <Box
            sx={{
              display:'flex',
              alignItems:'center',
              // borderColor:`${color}.main`,
              // borderStyle:'solid',
              // borderWidth:1,
              // borderRadius:1,
              px:2,
              py:1
            }}
          >
            {/* Text */}
            <Typography variant="button" sx={{lineHeight:1, color:`${color}.main`, fontWeight:600, mr:3}}>{text}</Typography>
            
            {/*
            DialogTemplate
            - The DialogTemplate tooltip is inside the DialogTemplate component
            - Add tooltipInfo and tooltipBg props to DialogTemplate to modify
            */}
            {dialog}


            {/* Direct Link */}
            <TooltipTemplate
              info={infoSingle}
              background={theme.palette[color].main}
              color="#fff"
              direction="right"
            >
              <Link
                {...linkSingle}
              >
                <IconButton
                  // color="opinion"
                  sx={{
                    borderRadius:1,
                    p:.5,
                    mr:1,
                    color:'#fff',
                    backgroundColor:`${color}.main`,
                    transition:'.4s',
                    '&&:hover':{
                      backgroundColor:`${color}.dark`
                    }
                  }}
                >
                  <ViewHeadline sx={{fontSize:'28px !important'}} />
                </IconButton>
              </Link>
            </TooltipTemplate>

            {/* Split Link */}
            <TooltipTemplate
              info={infoSplit}
              background={theme.palette[color].main}
              color="#fff"
              direction="right"
            >
              <Link
                {...link}
              >
                <IconButton
                  // color="opinion"
                  sx={{
                    borderRadius:1,
                    p:.5,
                    color:'#fff',
                    backgroundColor:`${color}.main`,
                    transition:'.4s',
                    '&&:hover':{
                      backgroundColor:`${color}.dark`
                    }
                  }}
                >
                  <VerticalSplit sx={{fontSize:'28px !important', transform:'rotate(180deg)'}} />
                </IconButton>
              </Link>
            </TooltipTemplate>

          </Box>

          {/* Info Modal */}
          {infoModal
            ?      
              <InfoModalTemplate
                info={infoModal.info}
                details={
                  <>
                    <Typography
                      sx={{
                        // display:'flex',
                        // alignItems:'center'
                        textAlign:'left',
                        mb:3
                      }}
                    >
                      The <ViewHeadline sx={{mb:-.75, backgroundColor:`${color}.main`, color:'#fff', borderRadius:1, p:.2}} /> icon will open up your content in a full page popup screen that does <span style={{fontWeight:700}}>not</span> show the current content or assignment criteria.
                    </Typography>

                    <Typography
                      sx={{
                        // display:'flex',
                        // alignItems:'center'
                        textAlign:'left'
                      }}
                    >
                      The <VerticalSplit sx={{mb:-.75, backgroundColor:`${color}.main`, color:'#fff', borderRadius:1, p:.2}} /> icon will open up your content in a split screen where you <span style={{fontWeight:700}}>can</span> view the content you are responding to as well as the assignment criteria.
                    </Typography>
                  </>
                
                }
                setWidth={infoModal.setWidth}
              />
            :
              null
          }
        </Grid>
  )
}