import { addDoc, arrayRemove, arrayUnion, collection, collectionGroup, deleteDoc, doc, getDoc, onSnapshot, orderBy, query, serverTimestamp, setDoc, where } from "firebase/firestore";
import { db } from "../firebase";
import { useEffect, useState } from "react";
import { useAuth } from "../context/authContext";





// ==========================================================================================================================
//      NOTES ON WHICH FILES API CALLS HAVE NOT BEEN MOVED TO MUTATIONS
// ==========================================================================================================================
// — AssignmentUpdate
// — ModalRegister
// — ModalRegisterLive
// — ModalRegisterTeacherLive
// — Register
// — RegisterLive
// — RegisterStaging
// — DashboardStudents (check where this is used)
// — Register
// — Register
// — Register

// — OpinionsBrowse (UNUSED)
// — AccountDashboardGeneral (UNSURE OF USAGE)















// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      AUTH API
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------

// export const registerUser = async(userObj) => {
//   const { userId, payload } = userObj;

//   try {
//     const userRef = doc(db, 'users', userId );
//     const userPayload = {
//       id: userId,
//       active: true,
//       displayName: registerUsername || '',
//       firstName: registerNew.firstName || '',
//       lastName: registerNew.lastName || '',
//       type: isTeacher ? 'teacher' : 'student',
//       domain: testDomain || '',
//       // userRole: arrayUnion('free'),
//       // userType: arrayUnion(isAccredited ? 'investor' : 'founder'),
//       createdAt: serverTimestamp()
//     }

//     await setDoc(userRef, userPayload);

//   }

//   catch (error) {

//   }
// }


// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------




// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------






// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------











































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      COURSE MODELS API
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------

// Create a new courseModel in the courseModels path
export const createCourseModel = async (courseModelObj) => {
  const { courseModelPayload } = courseModelObj;
  try {
    // Create courseModel in courseModel collection
    const courseModelRef = collection(db, 'course-models');
    // const courseModelPayload = courseModelPayload
    const newCourseModel = await addDoc(courseModelRef, courseModelPayload);
    return {id: newCourseModel.id, status:"success"};

  } catch (error) {
    console.log('Error on creating courseModel', error);
    return {id: null, status:"error"};
  }
}

export const duplicateCourseModel = async (courseModelObj) => {
  const { courseId, payload, } = courseModelObj;
  try {
    // Create courseModel in courseModel collection based on duplicated courseModel
    const courseModelRef = collection(db, 'course-models');
    const courseModelPayload = payload;

    // Delete old id so firestore will assign a new one on duplicate creation
    delete courseModelPayload.id;

    // Upload/Create duplicated courseModel on firestore
    const duplicatedAssignment = await addDoc(courseModelRef, courseModelPayload);

    // Return the new document id
    return {id: duplicatedAssignment.id, status:"success"}
    
  } catch (error) {
    console.log('Error on creating courseModel', error);
    return {id: null, status:"error"}

  }
}




// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------
// Create a new courseModel in the courseModels path
export const updateCourseModel = async (courseModelObj) => {
  const { courseModelId, courseModelPayload } = courseModelObj;
  try {
    // Create courseModel in courseModel subcollection
    const courseModelRef = doc(db, 'course-models', courseModelId);
    const updatedAssignment = await setDoc(courseModelRef, courseModelPayload, {merge:true});
    return "success"

  } 
  
  catch (error) {
    console.log('Error on creating courseModel', error);
    return "error"
  }
}





// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteCourseModel = async (courseModelObj) => {
  const { courseModelId } = courseModelObj;
  try {
    await deleteDoc(doc(db, 'course-models', courseModelId));
    return "success"
  }

  catch (error) {
    return "error"
  }
  
}





// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

// Hook to get all courseModels by a specific teacher using a snapshot listener
export const useGetAllCourseModels = (courseModelsObj) => {
  const { teacherId } = courseModelsObj;
  const [courseModels, setCourseModels] = useState();

  const getAllCourseModels = async () => {
    try {
      const collectionRef = collection(db, 'course-models');
      const q = query(collectionRef,
        where('teacher', '==', teacherId),
        // orderBy('courseModelCreatedAt', 'desc')
      );
      onSnapshot(q, (snapshot) => {
        setCourseModels(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })
  
    } catch (error) {
      console.log('Error fetching all teacher courseModels', error)
    }
  }
  
  useEffect(() => {
    if (teacherId) {
      getAllCourseModels();
    }
  }, [teacherId])

  return courseModels ? courseModels : null

};

// Function to get courses models by teacher id
export const getAllCourseModels = async (courseModelsObj) => {
  const { teacherId, setCourseModels } = courseModelsObj;
  try {

    const collectionRef = collection(db, 'course-models');
    const q = query(collectionRef,
      where('teacher', '==', teacherId),
      // orderBy('courseModelCreatedAt', 'desc')
    );
    onSnapshot(q, (snapshot) => {
      setCourseModels(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    })

  } catch (error) {
    console.log('Error fetching all teacher courseModels', error)
  }
}



// Hook to get courseModel by id using a snapshot listener
export const useGetCourseModel = (courseModelId) => {
  const [courseModel, setCourseModel] = useState();

  const getCourseModel = async () => {
    try {
      const docRef = doc(db, 'course-models', courseModelId);  
      onSnapshot(docRef, (doc) => {
        setCourseModel({ ...doc.data(), id: doc.id });
      });
    }
    
    catch (error) {
      console.log("Error getting courseModel", error);
    }
  }

  useEffect(() => {
    if (courseModelId) {
      getCourseModel();
    }
  }, [courseModelId])

  return courseModel ? courseModel : null

};

// Function to get a single course model by id
export const getCourseModel = async (courseModelObj) => {
  const { courseModelId, setCourseModel } = courseModelObj;
  try {
    const docRef = doc(db, 'course-models', courseModelId);  
    onSnapshot(docRef, (doc) => {
      setCourseModel({ ...doc.data(), id: doc.id });
    });
  }
  
  catch (error) {
    console.log("Error getting courseModel", error);
  }
}

















































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      ASSIGNMENT MODELS API
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------

// Create a new assignmentModel in the assignmentModels path
export const createAssignmentModel = async (assignmentModelObj) => {
  const { assignmentModelPayload } = assignmentModelObj;
  try {
    // Create assignmentModel in assignmentModel collection
    const assignmentModelRef = collection(db, 'assignment-models');
    // const assignmentModelPayload = assignmentModelPayload
    const newAssignment = await addDoc(assignmentModelRef, assignmentModelPayload);
    return {id: newAssignment.id, status:"success"};

  } catch (error) {
    console.log('Error on creating assignmentModel', error);
    return {id: null, status:"error"};
  }
}

export const duplicateAssignmentModel = async (assignmentModelObj) => {
  const { courseId, payload, } = assignmentModelObj;
  try {
    // Create assignmentModel in assignmentModel collection based on duplicated assignmentModel
    const assignmentModelRef = collection(db, 'assignment-models');
    const assignmentModelPayload = payload;

    // Delete old id so firestore will assign a new one on duplicate creation
    delete assignmentModelPayload.id;

    // Upload/Create duplicated assignmentModel on firestore
    const duplicatedAssignment = await addDoc(assignmentModelRef, assignmentModelPayload);

    // Return the new document id
    return {id: duplicatedAssignment.id, status:"success"}
    
  } catch (error) {
    console.log('Error on creating assignmentModel', error);
    return {id: null, status:"error"}

  }
}




// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------
// Create a new assignmentModel in the assignmentModels path
export const updateAssignmentModel = async (assignmentModelObj) => {
  const { assignmentModelId, assignmentModelPayload } = assignmentModelObj;
  console.log('assignmentmodelobj', assignmentModelId)
  try {
    // Create assignmentModel in assignmentModel subcollection
    const assignmentModelRef = doc(db, 'assignment-models', assignmentModelId);
    const updatedAssignment = await setDoc(assignmentModelRef, assignmentModelPayload, {merge:true});
    return {status:"success"}

  } 
  
  catch (error) {
    console.log('Error on creating assignmentModel', error);
    return {status:"error"}
  }
}





// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteAssignmentModel = async (assignmentModelObj) => {
  const { assignmentModelId } = assignmentModelObj;
  try {
    await deleteDoc(doc(db, 'assignment-models', assignmentModelId));
    return {status: "success"}
  }

  catch (error) {
    return {status: "error"}
  }
  
}





// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

// Hook to get all assignmentModels by a specific teacher using a snapshot listener
export const useGetAllAssignmentModels = (assignmentModelsObj) => {
  const { teacherId } = assignmentModelsObj;
  const [assignmentModels, setAssignmentModels] = useState();

  const getAllAssignmentModels = async () => {
    try {
      const collectionRef = collection(db, 'assignment-models');
      const q = query(collectionRef,
        where('assignmentAuthor', '==', teacherId),
        // orderBy('assignmentModelCreatedAt', 'desc')
      );
      onSnapshot(q, (snapshot) => {
        setAssignmentModels(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })
  
    } catch (error) {
      console.log('Error fetching all teacher assignmentModels', error)
    }
  }
  
  useEffect(() => {
    if (teacherId) {
      getAllAssignmentModels();
    }
  }, [teacherId])

  return assignmentModels ? assignmentModels : null

};

// Hook to get all assignmentModels by a specific teacher using a snapshot listener
export const useGetAllAssignmentModelsByCourse = (assignmentModelsObj) => {
  const { teacherId, courseId } = assignmentModelsObj;
  const [assignmentModels, setAssignmentModels] = useState();

  const getAllAssignmentModelsByCourse = async () => {
    try {
      const collectionRef = collection(db, 'assignment-models');
      const q = query(collectionRef,
        where('assignmentAuthor', '==', teacherId),
        where('courseModelId', '==', courseId)
      );
      onSnapshot(q, (snapshot) => {
        setAssignmentModels(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })
  
    } catch (error) {
      console.log('Error fetching all teacher assignmentModels by course', error)
    }
  }
  
  useEffect(() => {
    if (teacherId) {
      getAllAssignmentModelsByCourse();
    }
  }, [teacherId])

  return assignmentModels ? assignmentModels : null

};


// Function to get all assignmentModels by a specific teacher using a snapshot listener
export const getAllAssignmentModels = async (assignmentModelsObj) => {
  const { teacherId, setAssignmentModels } = assignmentModelsObj;
  try {

    const collectionRef = collection(db, 'assignment-models');
    const q = query(collectionRef,
      where('assignmentAuthor', '==', teacherId),
      orderBy('assignmentModelCreatedAt', 'desc')
    );
    onSnapshot(q, (snapshot) => {
      setAssignmentModels(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    })

  } catch (error) {
    console.log('Error fetching all teacher assignmentModels', error)
  }
}


// Function to get all assignmentModels by a specific teacher using a snapshot listener
export const getOtherAssignmentModels = async (assignmentModelsObj) => {
  const { teacherId, courseId, setOtherAssignmentModels } = assignmentModelsObj;
  try {

    const collectionRef = collection(db, 'assignment-models');
    const q = query(collectionRef,
      where('assignmentAuthor', '==', teacherId),
      where('courseModelId', '!=', courseId),
      orderBy('courseModelId', 'desc'),
      orderBy('updatedAt', 'desc')
    );
    onSnapshot(q, (snapshot) => {
      setOtherAssignmentModels(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    })

  } catch (error) {
    console.log('Error fetching all teacher assignmentModels', error)
  }
}



// Get assignmentModel by id using a snapshot listener
export const useGetAssignmentModel = (assignmentModelId) => {
  const [assignmentModel, setAssignmentModel] = useState();

  const getAssignmentModel = async () => {
    try {
      const docRef = doc(db, 'assignment-models', assignmentModelId);  
      onSnapshot(docRef, (doc) => {
        setAssignmentModel({ ...doc.data(), id: doc.id });
      });
    }
    
    catch (error) {
      console.log("Error getting assignmentModel", error);
    }
  }

  useEffect(() => {
    if (assignmentModelId) {
      getAssignmentModel();
    }
  }, [assignmentModelId])

  return assignmentModel ? assignmentModel : null

};



















































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      COURSES API
//      INVITES API
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------

// Create new course doc AND new invite doc
export const createCourse = async (courseObj) => {
  const { coursePayload, invitePayload, inviteCode } = courseObj;
  try {
    // Create course in course collection
    const courseRef = collection(db, 'courses');
    const newCourse = await addDoc(courseRef, coursePayload);

    // Create invite in invites collection
    const inviteRef = doc(db, 'invites', inviteCode);
    const updatedInvitePayload = {
      ...invitePayload,
      courseId: newCourse.id,
    }
    const newInvite = await setDoc(inviteRef, updatedInvitePayload);

    // Return the new course doc id and status
    return {id: newCourse.id, status:"success"}


  } catch (error) {
    console.log('Error on creating course', error);
    return {id: null, status:"error"};

  }

}

// ADD STUDENT TO CLASS IN FIRESTORE
export const requestToEnroll = async (enrollObj) => {
  const { invite, userId, payload } = enrollObj;
  try {
    // Create course in course collection
    const studentRef = doc(db, 'courses', invite.courseId, 'courseStudents', userId);
    const newStudent = await setDoc(studentRef, payload);

    return {status:'success'}
    // authContext.handleAlert("Your request to enroll has been submitted");
    // console.log('newDoc', newCourse.id);


  } catch (error) {
    console.log('Error on enrolling student', error);
    return {status:'error'}
  }

}




// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------

export const updateCourse = async (courseObj) => {
  const { courseId, payload } = courseObj;
  try {
    const courseRef = doc(db, 'courses', courseId);
    const coursePayload = payload
    const updatedCourse = await setDoc(courseRef, coursePayload, {merge:true});
    return {status: "success"}
  }

  catch (error) {
    console.log('Error updating course', error);
    return {status: "error"}
  }
}

export const updateInvite = async (InviteObj) => {
  const { courseId, coursePayload, invitePayload, newInviteCode, oldInviteCode } = InviteObj;
  try {
    // Update course in course collection
    const docRef = doc(db, 'courses', courseId);
    const updatedCourse = await setDoc(docRef, coursePayload, {merge:true});

    // Create invite in invites collection
    const inviteRef = doc(db, 'invites', newInviteCode);
    const newInvite = await setDoc(inviteRef, invitePayload);

    await deleteDoc(doc(db, 'invites', oldInviteCode))

    return {status:'success'}

  } catch (error) {
    console.log('Error on creating invite', error);
    return {status:'error'}
  }
}




// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

// Function to delete course
export const deleteCourse = async (courseObj) => {
  const { courseId } = courseObj;
  try {
    await deleteDoc(doc(db, 'courses', courseId));
    return {status: 'success'}
  }

  catch (error) {
    return {status: 'error'}
  }
  
}





// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

// Get course by id without a snapshot listener
// Use this when the data does not need to be updated in real time
export const getCourseStatic = async (courseObj) => {
  const { courseId, set } = courseObj;
  try {
    const docRef = doc(db, 'courses', courseId);  
    const docSnap = await getDoc(docRef);
    const docData = {...docSnap.data(), id: docSnap.id};
    set(docData);
    return "success"

  } catch (error) {
    console.log(`Error on getting course doc`, error);
    return "error"
  }
}

// Get course by id using a snapshot listener
// Use this to retrieve real time updates
export const useGetCourse = (courseId) => {
  const [course, setCourse] = useState();

  const getCourse = async () => {
    try {
      const docRef = doc(db, "courses", courseId);
      onSnapshot(docRef, (doc) => {
        setCourse({ ...doc.data(), id: doc.id });
      });
    }
    
    catch (error) {
      console.log("Error getting course", error);
    }
  }

  useEffect(() => {
    if (courseId) {
      getCourse();
    }
  }, [courseId])

  return course ? course : null

};

// Hook to get courses by teacher id
export const useGetCoursesByTeacher = (coursesObj) => {
  const { teacherId } = coursesObj;
  const [courses, setCourses] = useState();

  const getCoursesByTeacher = () => {
    try {
      const collectionRef = collection(db, 'courses');
      const q = query(collectionRef, where('teacher', '==', teacherId));
      onSnapshot(q, (snapshot) => {
        setCourses(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })
    } catch (error) {
      console.log('Error fetching courses', error)
    }
  }
  
  useEffect(() => {
    if (teacherId) {
      getCoursesByTeacher();
    }
  }, [teacherId])

  return courses ? courses : null

};


// Function to get courses by teacher id
export const getCoursesByTeacher = (coursesObj) => {
  const { teacherId, setCourses } = coursesObj;
  console.log('courseObj', coursesObj)
  try {
    const collectionRef = collection(db, 'courses');
    const q = query(collectionRef, where('teacher', '==', teacherId));
    onSnapshot(q, (snapshot) => {
      setCourses(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    })
  } catch (error) {
    console.log('Error fetching courses', error)
  }
}


// Get all courses for the current logged in student
export const queryStudentCourses = (coursesObj) => {
  const { userId, setCourses } = coursesObj;
  try {
    const collectionRef = collection(db, 'courses');
    const q = query(collectionRef, where('students', 'array-contains', userId));
    onSnapshot(q, (snapshot) => {
      setCourses(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    })
  } catch (error) {
    console.log('Error fetching student courses', error)
  }
}

// GET INVITE INCLUDING CLASS INFO FROM CODE
export const getInvite = async (inviteObj) => {
  try {
    const { inviteCode, setInvite } = inviteObj;
    const docRef = doc(db, 'invites', inviteCode.setInvite);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      setInvite(docSnap.data());
      return {status:'success'}
      // authContext.handleAlert("We found your class");
    } else {
      // doc.data() will be undefined in this case
      return {status:'error'}
      // authContext.handleAlert("That invite code does not match a class");
    }
  }

  catch (error) {
    console.log('Error on getting invite', error);
  }
}









































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      COURSE STUDENTS API
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------
// ENROLLING STUDENTS TO CLASS
export const enrollStudent = async (enrollObj) => {
  const { courseId, studentId, course } = enrollObj
  try {
    // enroll student in courseStudents subcollection
    const studentRef = doc(db, 'courses', courseId, 'courseStudents', studentId);
    const studentPayload = {
      enrolled: true
    }
    const updateStudent = await setDoc(studentRef, studentPayload, { merge: true });

    // Add student to the class 'students' array
    const courseRef = doc(db, 'courses', courseId);
    const coursePayload = {
      students: arrayUnion(studentId)
    }
    const updateCourse = await setDoc(courseRef, coursePayload, { merge: true });

    // Add teacher and course to student doc
    const teacherRef = doc(db, 'users', studentId);
    const teacherPayload = {
      teachers: arrayUnion({ course: course.id, teacher: course.teacher, teacherFirst: course.teacherFirst, teacherLast: course.teacherLast, teacherTitle: course.teacherTitle })
    }
    const updateTeacher = await setDoc(teacherRef, teacherPayload, { merge: true });

    return {status:'success'};

  } catch (error) {
    console.log('Error enrolling student', error);
    return {status:'error'};
  }
}



// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------




// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

// Student is able to remove themselves from awaiting teacher approval list or
// Teacher is able to remove a student from the awaiting approval list
export const removeStudent = async (removeObj) => {
  console.log('removeObj', removeObj);
  const { courseId, studentId } = removeObj;
  console.log('courseId', courseId);
  console.log('studentId', studentId);
  try {
    // Remove student from courseStudents subcollection
    await deleteDoc(doc(db, 'courses', courseId, 'courseStudents', studentId));
    
    // Remove student from the class 'students' array
    const courseRef = doc(db, 'courses', courseId);
    const coursePayload = {
      students: arrayRemove(studentId)
    }
    const updateCourse = await setDoc(courseRef, coursePayload, {merge: true});

    return {status:'success'}


  } catch (error) {
    console.log('Error removing student', error);
    return {status:'error'}
  }
}







// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------
// Get course students collection
export const useGetCourseStudents = (courseId) => {
  const [students, setStudents] = useState();

  const getStudents = async () => {
    try {
      const collectionRef = collection(db, 'courses', courseId, 'courseStudents' );
      const q = query(collectionRef, where('enrolled', '==', true));
      onSnapshot(q, (snapshot) => {
        setStudents( snapshot.docs.map((doc) => ( doc.data() )) );
      })
    } catch (error) {
      console.log('Error getting students', error);
    }
  }

  useEffect(() => {
    if (courseId) {
      getStudents();
    }
  }, [courseId])

  return students ? students : null

};

export const getStudents = async (studentsObj) => {
  const { courseId, setStudents } = studentsObj;
  try {
    const collectionRef = collection(db, 'courses', courseId, 'courseStudents');
    onSnapshot(collectionRef, (snapshot) => {
      setStudents(snapshot.docs.map((doc) => (doc.data())));
    })
  } catch (error) {
    console.log('Error getting students', error);
  }
}











































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      ASSIGNMENTS API
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------

// Create a new assignment in the course/assignments path
export const createAssignment = async (assignmentObj) => {
  const { courseId, assignmentPayload } = assignmentObj;
  try {
    // Create assignment in assignment collection
    const assignmentRef = collection(db, 'courses', courseId, 'assignments');
    // const assignmentPayload = assignmentPayload
    const newAssignment = await addDoc(assignmentRef, assignmentPayload);
    return {id: newAssignment.id, status:"success"};

  } catch (error) {
    console.log('Error on creating assignment', error);
    return {id: null, status:"error"};
  }
}

export const duplicateAssignment = async (assignmentObj) => {
  const { courseId, assignmentPayload, } = assignmentObj;
  try {
    // Create assignment in assignment collection based on duplicated assignment
    const assignmentRef = collection(db, 'courses', courseId, 'assignments');

    // Delete old id so firestore will assign a new one on duplicate creation
    delete assignmentPayload.id;

    // Upload/Create duplicated assignment on firestore
    const duplicatedAssignment = await addDoc(assignmentRef, assignmentPayload);

    // Return the new document id
    return {id: duplicatedAssignment.id, status:"success"}
    
  } catch (error) {
    console.log('Error on creating assignment', error);
    return {id: null, status:"error"}

  }
}




// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------
// Create a new assignment in the course/assignments path
export const updateAssignment = async (assignmentObj) => {
  const { courseId, assignmentId, assignmentPayload } = assignmentObj;
  try {
    // Create assignment in assignment subcollection
    const assignmentRef = doc(db, 'courses', courseId, 'assignments', assignmentId);
    const updatedAssignment = await setDoc(assignmentRef, assignmentPayload, {merge:true});
    return "success"

  } 
  
  catch (error) {
    console.log('Error on creating assignment', error);
    return "error"
  }
}





// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteAssignment = async (assignmentObj) => {
  const { courseId, assignmentId } = assignmentObj;
  console.log('assignmentObj', assignmentObj)
  try {
    await deleteDoc(doc(db, 'courses', courseId, 'assignments', assignmentId));
    return {status:'success'};
  }

  catch (error) {
    console.log('Error on deleting assignment', error);
    return {status:'error'};
  }
  
}





// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

// Get all assignments for the current logged in student
export const queryStudentAssignments = (assignmentsObj) => {
  const { userId, setAssignments } = assignmentsObj;
  try {
    const collectionRef = collectionGroup(db, 'assignments');
    const q = query(collectionRef,
      where('assignmentStudentsAssignedIds', 'array-contains', userId),
      where('assignmentActive', '==', true));
    onSnapshot(q, (snapshot) => {
      setAssignments(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    })
  } catch (error) {
    console.log('Error fetching assignments', error)
  }
}

// Get all assignments by a specific teacher using a snapshot listener
export const useGetAllTeacherAssignments = (assignmentsObj) => {
  const { teacherId } = assignmentsObj;
  const [assignments, setAssignments] = useState();

  const getAllTeacherAssignments = async () => {
    try {
  
      const collectionRef = collectionGroup(db, 'assignments');
      const q = query(collectionRef,
        where('courseTeacher', '==', teacherId),
        orderBy('assignmentCreatedAt', 'desc')
      );
      onSnapshot(q, (snapshot) => {
        setAssignments(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })
  
    } catch (error) {
      console.log('Error fetching all teacher assignments', error)
    }
  }
  
  useEffect(() => {
    if (teacherId) {
      getAllTeacherAssignments();
    }
  }, [teacherId])

  return assignments ? assignments : null

};


export const getAllTeacherAssignments = async (assignmentsObj) => {
  const { userId, setAssignments } = assignmentsObj;
  try {

    const collectionRef = collectionGroup(db, 'assignments');
    const q = query(collectionRef,
      where('courseTeacher', '==', userId),
      orderBy('assignmentCreatedAt', 'desc')
    );
    onSnapshot(q, (snapshot) => {
      setAssignments(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    })

  } catch (error) {
    console.log('Error fetching all teacher assignments', error)
  }
}



// Get assignment by id using a snapshot listener
export const useGetAssignment = (courseId, assignmentId) => {
  const [assignment, setAssignment] = useState();

  const getAssignment = async () => {
    try {
      const docRef = doc(db, 'courses', courseId, 'assignments', assignmentId);  
      onSnapshot(docRef, (doc) => {
        setAssignment({ ...doc.data(), id: doc.id });
      });
    }
    
    catch (error) {
      console.log("Error getting assignment", error);
    }
  }

  useEffect(() => {
    if (courseId && assignmentId) {
      getAssignment();
    }
  }, [courseId, assignmentId])

  return assignment ? assignment : null

};

// Get all assignments for the teacher
export const getTeacherAssignmentsByCourse = async (teacherAssignmentsObj) => {
  const { courseId, setAssignments } = teacherAssignmentsObj;
  try {
    const collectionRef = collection(db, 'courses', courseId, 'assignments');
    // No query needed for teacher — get 'em all.
    onSnapshot(collectionRef, (snapshot) => {
      setAssignments(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    })

    return {status:'success'};

  } catch (error) {
    console.log('Error fetching assignments', error);
    return {status:'error'};
  }
}

// Get assignments that are active, not drafts, and assigned to the current student
export const getStudentAssignmentsByCourse = async (studentAssignmentsObj) => {
  const { courseId, userId, setAssignments } = studentAssignmentsObj;
  try {
    const collectionRef = collection(db, 'courses', courseId, 'assignments');
    const q = query(
      collectionRef, 
      where('assignmentStudentsAssignedIds', 'array-contains', userId),
      where('assignmentDraft', '==', false),
      where('assignmentActive', '==', true)
    );
    onSnapshot(q, (snapshot) => {
      setAssignments(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    })
    
    return {status:'success'};

  } catch (error) {
    console.log('Error fetching assignments', error);
    return {status:'error'};
  }
}
















































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      OPINION API
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------





// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------





// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------






// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------


// Get all opinions based on course, assignment, and author
export const getOpinionsByCourseAssignmentAuthor = async (opinionObj) => {
  try {
    const { studentId, courseId, assignmentId, setOpinionContent } = opinionObj;
    const opinionRef = collectionGroup(db, 'opinions');
    const opinionQ = query(
      opinionRef,
      where('courseId', '==', courseId),
      where('assignmentId', '==', assignmentId),
      where('author', '==', studentId)
    );
    onSnapshot(opinionQ, (snapshot) => {
      const opinionSnap = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setOpinionContent(opinionSnap)
    })

    return {status: 'success'}
  }

  catch (error) {
    return {status: 'error'}
  }

}

// Get all opinions from a specific course for a specific student
export const getOpinionsByCourse = async (opinionObj) => {
  const { authorId, courseId, setOpinions } = opinionObj;
  try {
    const collectionRef = collectionGroup(db, 'opinions');
    const q = query(
      collectionRef,
      where('opinionAuthor', '==', authorId),
      where('courseId', '==', courseId)
    );
    onSnapshot(q, (snapshot) => {
      setOpinions(
        snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    });
  } catch (error) {
    console.log(`error on getting Opinion docs`, error);
  }
};

// Get opinions by courseId, assignmentId, and author
export const getStudentOpinions = (opinionObj) => {
  const { assignment, userId, setOpinion } = opinionObj;
  try {
    const opinionRef = collectionGroup(db, 'opinions')
    const qOpinion = query(opinionRef, 
      where('courseId', '==', assignment.courseId),
      where('assignmentId', '==', assignment.id),
      where('author', '==', userId)
    );
    onSnapshot(qOpinion, (snapshot) => {
      setOpinion(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    })

    return "success"
  }

  catch (error) {
    console.log('Error getting student opinions');
    return "error"
  }
}

// Get opinions that are allowed for teachers or students
// All opinions are allowed for teachers
// Get opinions that are active and not drafts for students
export const getAllowedOpinions = async (opinionObj) => {
  const { courseId, assignmentId, setOpinions, assignment, isTeacher, userId  } = opinionObj;
  const collectionRef = collection(db, 'courses', courseId, 'assignments', assignmentId, 'opinions');

  const getTeacherOpinions = async () => {
    try {
      onSnapshot(collectionRef, (snapshot) => {
        setOpinions(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })

    } catch (error) {
      console.log('Error getting opinions', error);
    }
  }  

  const getStudentOpinions = async () => {
    if (assignment.assignmentHideSubmissions) {
      // Get only the current students submission to display
      try {
        const collectionRef = collection(db, 'courses', courseId, 'assignments', assignmentId, 'opinions');
        const q = query(collectionRef, 
          where('active', '==', true),
          where('draft', '==', false),
          where('author', '==', userId)
        );
        onSnapshot(q, (snapshot) => {
          setOpinions(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        })
  
      } catch (error) {
        console.log('Error getting current student opinion', error);
      }

    } else {
      // Get all the active, non-draft submissions to display
      try {
        const collectionRef = collection(db, 'courses', courseId, 'assignments', assignmentId, 'opinions');
        const q = query(collectionRef, 
          where('active', '==', true),
          where('draft', '==', false),
        );
        onSnapshot(q, (snapshot) => {
          setOpinions(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        })
  
      } catch (error) {
        console.log('Error getting opinions', error);
      }
    }
  }

  if (isTeacher) {
    getTeacherOpinions();
  } else {
    getStudentOpinions();
  }


  return {status:"success", role:isTeacher ? 'teacher' : 'student'}
}

// Hook to get user opinions 
export const useGetUserOpinion = (userObj) => {
  const { courseId, assignmentId } = userObj;
  const authContext = useAuth(); 
  const [userOpinion, setUserOpinion] = useState();

  const getUserOpinion = async () => {
    try {
      const docRef = doc(db, 'courses', courseId, 'assignments', assignmentId, 'opinions', `${authContext.account.id}`);
      onSnapshot(docRef, (doc) => {
        if (doc.data()) {
          setUserOpinion({...doc.data(), id: doc.id});
        }
      })
      
    } catch (error) {
      console.log('Error getting user opinion', error);
    }
  }

  useEffect(() => {
    if (courseId && assignmentId) {
      getUserOpinion();
    }
  }, [courseId, assignmentId])

  return userOpinion ? userOpinion : null

}

// Get single opinion using a listener
export const getOpinion = async (opinionObj) => {
  const { courseId, assignmentId, opinionId, setOpinion } = opinionObj;
  try {
    const docRef = doc(db, 'courses', courseId, 'assignments', assignmentId, 'opinions', opinionId);  
    onSnapshot(docRef, (doc) => {
      setOpinion({...doc.data(), id: doc.id});
    })

  } catch (error) {
    console.log('Error getting opinion', error);
  }
}


// Get single opinion without a listener
export const getOpinionStatic = async (opinionObj) => {
  const { courseId, assignmentId, opinionId, setOpinion } = opinionObj;
  try {
    const opinionRef = doc(db, 'courses', courseId, 'assignments', assignmentId, 'opinions', opinionId);
    const docSnap = await getDoc(opinionRef);
    const docData = {...docSnap.data(), id: docSnap.id};
    setOpinion(docData);

    return {status: 'success'}
  }

  catch (error) {
    console.log('Error getting student response');
    return {status: 'error'}
  }

}
















































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      RESPONSE API
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------





// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------





// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------






// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

// Get all responses based on course, assignment, and author
export const getResponsesByCourseAssignmentAuthor = async (responseObj) => {
  const { studentId, courseId, assignmentId, setResponseContent } = responseObj;
  const responseRef = collectionGroup(db, 'responses');
  const responseQ = query(
    responseRef,
    where('courseId', '==', courseId),
    where('assignmentId', '==', assignmentId),
    where('author', '==', studentId)
  );
  onSnapshot(responseQ, (snapshot) => {
    const responseSnap = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setResponseContent(responseSnap);
  })

}

// Get all responses from a specific course for a specific student
export const getResponsesByCourse = async (responseObj) => {
  const { authorId, courseId, setResponses } = responseObj;
  try {
    const collectionRef = collectionGroup(db, 'responses');
    const q = query(
      collectionRef,
      where('responseAuthor', '==', authorId),
      where('courseId', '==', courseId)
    );
    onSnapshot(q, (snapshot) => {
      setResponses(
        snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    });
  } catch (error) {
    console.log(`error on getting Response docs`, error);
  }
};


export const getStudentResponses = (responseObj) => {
  const { assignment, userId, setResponse } = responseObj;
  try {
    const opinionRef = collectionGroup(db, 'responses')
    const qOpinion = query(opinionRef, 
      where('courseId', '==', assignment.courseId),
      where('assignmentId', '==', assignment.id),
      where('author', '==', userId)
    );
    onSnapshot(qOpinion, (snapshot) => {
      setResponse(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    })

    return "success"
  }

  catch (error) {
    console.log('Error getting student opinions');
    return "error"
  }
}


export const getAllowedResponses = async (responseObj) => {
  const { courseId, assignmentId, setResponses, assignment, isTeacher, userId } = responseObj;
  const collectionRef = collectionGroup(db, 'responses');

  const getTeacherResponses = async () => {
    try {
      const q = query(collectionRef, 
        where('courseId', '==', courseId),
        where('assignmentId', '==', assignmentId)
      );
      onSnapshot(q, (snapshot) => {
        setResponses(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })

    } catch (error) {
      console.log('Error getting responses', error);
    }
  }

  const getStudentResponses = async () => {
    if (assignment.assignmentHideSubmissions) {
      try {
        const q = query(collectionRef, 
          where('courseId', '==', courseId),
          where('assignmentId', '==', assignmentId),
          where('active', '==', true),
          where('draft', '==', false),
          where('author', '==', userId)
        );
        onSnapshot(q, (snapshot) => {
          setResponses(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        })
  
      } catch (error) {
        console.log('Error getting current student response', error);
      }
  
    } else {
      try {
        const q = query(collectionRef, 
          where('courseId', '==', courseId),
          where('assignmentId', '==', assignmentId),
          where('active', '==', true),
          where('draft', '==', false)
        );
        onSnapshot(q, (snapshot) => {
          setResponses(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        })
  
      } catch (error) {
        console.log('Error getting responses', error);
      }
    }
  }

  if (isTeacher) {
    getTeacherResponses();
  } else {
    getStudentResponses();
  }



  return {status:"success", role:isTeacher ? 'teacher' : 'student'}
}

export const getOpinionResponses = async (responseObj) => {
  const { courseId, assignmentId, id, isTeacher, setOpinionResponses } = responseObj;
  try {
    if (isTeacher) {
      const collectionRef = collection(db, 'courses', courseId, 'assignments', assignmentId, 'opinions', id, 'responses');
      const q = query(collectionRef, where('responseDraft', '==', false));
      onSnapshot(q, (snapshot) => {
        setOpinionResponses(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })
    } else {
      const collectionRef = collection(db, 'courses', courseId, 'assignments', assignmentId, 'opinions', id, 'responses');
      const q = query(collectionRef, where('active', '==', true));
      onSnapshot(q, (snapshot) => {
        setOpinionResponses(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })
    }
  } catch (error) {
    console.log(`Error on getting response doc`, error);
  }
}

// Get single response without a listener
export const getResponseStatic = async (responseObj) => {
  const { courseId, assignmentId, opinionId, responseId, setResponse } = responseObj;
  try {
    const responseRef = doc(db, 'courses', courseId, 'assignments', assignmentId, 'opinions', opinionId, 'responses', responseId);
    const docSnap = await getDoc(responseRef);
    const docData = {...docSnap.data(), id: docSnap.id};
    setResponse(docData);

    return {status: 'success'}
  }

  catch (error) {
    console.log('Error getting student response');
    return {status: 'error'}
  }

}












































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      REPLY API
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------





// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------





// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------






// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

// Get all replies based on course, assignment, and author
export const getRepliesByCourseAssignmentAuthor = async (replyObj) => {
  const { studentId, courseId, assignmentId, setReplyContent } = replyObj;
  const replyRef = collectionGroup(db, 'replies');
  const replyQ = query(
    replyRef,
    where('courseId', '==', courseId),
    where('assignmentId', '==', assignmentId),
    where('author', '==', studentId)
  );
  onSnapshot(replyQ, (snapshot) => {
    const replySnap = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setReplyContent(replySnap);
  })

}

// Get all responses from a specific course for a specific student
export const getRepliesByCourse = async (replyObj) => {
  const { authorId, courseId, setReplies } = replyObj;
  try {
    const collectionRef = collectionGroup(db, 'replies');
    const q = query(
      collectionRef,
      where('replyAuthor', '==', authorId),
      where('courseId', '==', courseId)
    );
    onSnapshot(q, (snapshot) => {
      setReplies(
        snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    });
  } catch (error) {
    console.log(`error on getting Reply docs`, error);
  }
};

// Get Student Replies by course, assignment, and author
export const getStudentReplies = (opinionObj) => {
  const { assignment, userId, setReply } = opinionObj;
  try {
    const opinionRef = collectionGroup(db, 'replies')
    const qOpinion = query(opinionRef, 
      where('courseId', '==', assignment.courseId),
      where('assignmentId', '==', assignment.id),
      where('author', '==', userId)
    );
    onSnapshot(qOpinion, (snapshot) => {
      setReply(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    })

    return "success"
  }

  catch (error) {
    console.log('Error getting student opinions');
    return "error"
  }
}


// Get Teacher Replies or Student Replies based on if current user is a teacher
export const getAllowedReplies = async (replyObj) => {
  const { courseId, assignmentId, setReplies, assignment, isTeacher, userId } = replyObj;
  const collectionRef = collectionGroup(db, 'replies');

  const getTeacherReplies = async () => {
    try {
      const q = query(collectionRef, 
        where('courseId', '==', courseId),
        where('assignmentId', '==', assignmentId)
      );
      onSnapshot(q, (snapshot) => {
        setReplies(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })

    } catch (error) {
      console.log('Error getting replies', error);
    }
  }

  const getStudentReplies = async () => {
    if (assignment.assignmentHideSubmissions) {
      try {
        const q = query(collectionRef, 
          where('courseId', '==', courseId),
          where('assignmentId', '==', assignmentId),
          where('active', '==', true),
          where('draft', '==', false),
          where('author', '==', userId)
        );
        onSnapshot(q, (snapshot) => {
          setReplies(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        })
  
      } catch (error) {
        console.log('Error getting current student reply', error);
      }
      
    } else {
      try {
        const q = query(collectionRef, 
          where('courseId', '==', courseId),
          where('assignmentId', '==', assignmentId),
          where('active', '==', true),
          where('draft', '==', false)
        );
        onSnapshot(q, (snapshot) => {
          setReplies(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        })
  
      } catch (error) {
        console.log('Error getting replies', error);
      }
    }
  }

  if (isTeacher) {
    getTeacherReplies();
  } else {
    getStudentReplies();
  }


  return {status:"success", role:isTeacher ? 'teacher' : 'student'}
}

export const getResponseReplies = async (replyObj) => {
  const { courseId, assignmentId, opinionId, id, setResponseReplies, isTeacher } = replyObj;
  try {
    if (isTeacher) {
      const collectionRef = collection(db, 'courses', courseId, 'assignments', assignmentId, 'opinions', opinionId, 'responses', id, 'replies');
      const q = query(collectionRef, 
        where('replyDraft', '==', false)
      );
      onSnapshot(q, (snapshot) => {
        setResponseReplies(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })
    } else {
      const collectionRef = collection(db, 'courses', courseId, 'assignments', assignmentId, 'opinions', opinionId, 'responses', id, 'replies');
      const q = query(collectionRef, where('active', '==', true));
      onSnapshot(q, (snapshot) => {
        setResponseReplies(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })
    }
  } catch (error) {
    console.log(`Error on getting response replies`, error);
  }
}

// Get single reply without a listener
export const getReplyStatic = async (replyObj) => {
  const { courseId, assignmentId, opinionId, responseId, replyId, setReply } = replyObj;
  try {
    const replyRef = doc(db, 'courses', courseId, 'assignments', assignmentId, 'opinions', opinionId, 'responses', responseId, 'replies', replyId);
    const docSnap = await getDoc(replyRef);
    const docData = {...docSnap.data(), id: docSnap.id};
    setReply(docData);

    return {status: 'success'}
  }

  catch (error) {
    console.log('Error getting student reply');
    return {status: 'error'}
  }

}

















































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      UNDETERMINATE COLLECTION API
//      ** DOES NOT ALWAYS WRITE/READ FROM THE SAME COLLECTION **
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------

export const createUserGeneratorContent = async (contentObj) => {
  const { contentType, authContext, nameObj, courseId, assignmentId, opinionId, responseId } = contentObj;
  console.log(contentObj);
  try {
    let docRef = null;
    let payload = {};

    const defaultPayload = {
      assignmentId: assignmentId,
      active: false,
      author: authContext.account.id || '',
      authorDisplay: authContext.account.displayName || '',
      authorFirst: authContext.account.firstName || '',
      authorLast: authContext.account.lastName || '',
      displayName: nameObj.displayName || '',
      displayAvatar: nameObj.displayAvatar || '',
      draft: true,
      createdAt: serverTimestamp(),
    }


    if (contentType === "opinion") {
      docRef = doc(db, 'courses', courseId, 'assignments', assignmentId, 'opinions', authContext.account.id);
      payload = {
        ...defaultPayload,
        type:'opinion',
        opinionActive: false,
        opinionDraft: true,
      }
    } else if (contentType === "response") {
      docRef = doc(db, 'courses', courseId, 'assignments', assignmentId, 'opinions', opinionId, 'responses', authContext.account.id);
      payload = {
        ...defaultPayload,
        type:'response',
        opinionId: opinionId,
        responseActive: false,
        responseDraft: true,
      }
    } else if (contentType === "reply") {
      docRef = doc(db, 'courses', courseId, 'assignments', assignmentId, 'opinions', opinionId, 'responses', responseId, 'replies', authContext.account.id);
      payload = {
        type:'reply',
        opinionId: opinionId,
        responseId: responseId,
        replyActive: false,
        replyDraft: true,
      }
    };

    await setDoc(docRef, payload, {merge: true});
    return {status:'success'}
  } 
  
  catch (error) {
    console.log('Error creating content', error)
    return {status:'error'}
  }
}





// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------





// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------






// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------















































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      COMMENTS API
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------

// CREATE COMMENT
export const createComment = async (commentObj) => {
  try {
    const collectionRef = collection(db, 'courses', commentObj.current.courseId, 'assignments', commentObj.current.assignmentId, 'comments');

    const payload = commentObj.payload;

    await addDoc(collectionRef, payload);
    return {status:'success'}

  } catch (error) {
    console.log('Error on uploading Comment', error);
    return {status:'error'}
  }

}




// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------

// APPROVE COMMENT
export const approveComment = async (comment) => {
  try {
    const docRef = doc(db, 'courses', comment.courseId, 'assignments', comment.assignmentId, 'comments', comment.id);
    const docPayload = {
      active:true,
      approved:true
    }
    await setDoc(docRef, docPayload, {merge:true});
  }

  catch (error) {
    console.log('Error approving comment', error);
  }
}

// REJECT COMMENT
export const rejectComment = async (comment) => {
  try {
    const docRef = doc(db, 'courses', comment.courseId, 'assignments', comment.assignmentId, 'comments', comment.id);
    const docPayload = {
      active:false,
      approved:false
    }
    await setDoc(docRef, docPayload, {merge:true});
  }

  catch (error) {
    console.log('Error rejecting comment', error);
  }
}




// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------






// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

export const getOpinionComments = async (contentData) => {
  const { courseId, assignmentId, opinionId, setComments } = contentData;
  try {
    const collectionRef = collection(db, 'courses', courseId, 'assignments', assignmentId, 'comments');
    const qOpinion = query(
      collectionRef,
      orderBy('createdAt', 'asc'),
      where('type', '==', 'opinion'),
      where('assignmentId', '==', assignmentId),
      where('opinionId', '==', opinionId)
    )
    onSnapshot(qOpinion, (snapshot) => {
      setComments(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    })
  } catch (error) {
    console.log('Error getting opinion comments', error);
  }
} 

export const getResponseComments = async (contentData) => {
  const { courseId, assignmentId, opinionId, responseId, setComments } = contentData;
  try {
    const collectionRef = collection(db, 'courses', courseId, 'assignments', assignmentId, 'comments');
    const qResponse = query(
      collectionRef, 
      orderBy('createdAt', 'asc'),
      where('type', '==', 'response'),
      where('assignmentId', '==', assignmentId),
      where('opinionId', '==', opinionId),
      where('responseId', '==', responseId)
    )
    onSnapshot(qResponse, (snapshot) => {
      setComments(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    })
  } catch (error) {
    console.log('Error getting response comments', error);
  }
} 

export const getReplyComments = async (contentData) => {
  const { courseId, assignmentId, opinionId, responseId, replyId, setComments } = contentData;
  try {
    const collectionRef = collection(db, 'courses', courseId, 'assignments', assignmentId, 'comments');
    const qReply = query(
      collectionRef, 
      orderBy('createdAt', 'asc'),
      where('type', '==', 'reply'),
      where('assignmentId', '==', assignmentId),
      where('opinionId', '==', opinionId),
      where('responseId', '==', responseId),
      where('replyId', '==', replyId)
    )

    onSnapshot(qReply, (snapshot) => {
      setComments(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    })
  } catch (error) {
    console.log('Error getting reply comments', error);
  }
} 
















































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      GRADES API
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------





// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------




// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------






// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

export const useGetGradedStudentGrades = (gradesObj) => {
  const { userId } = gradesObj;
  const [grades, setGrades] = useState();

  const getGrades = () => {
    try {
      const collectionRef = collectionGroup(db, 'courseGrades');
      const q = query(collectionRef, where('author', '==', userId), where('graded', '==', true));
      onSnapshot(q, (snapshot) => {
        setGrades(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })
    } catch (error) {
      console.log('Error fetching grades', error)
    }
  }
  
  useEffect(() => {
    if (userId) {
      getGrades();
    }
  }, [userId])

  return grades ? grades : []

};


// Get grade for the current content from the courseGrades collection
export const getGrade = async (gradeObj) => {
  const { content, setCurrentGrading } = gradeObj; 
  try {
    const docName = `${content.author}-${content.type}-${content.id}-${content.assignmentId}`;
    const gradeRef = doc(db, 'grades', content.courseId, 'courseGrades', docName);
    onSnapshot(gradeRef, (doc) => {
      setCurrentGrading({ ...doc.data(), id: doc.id });
    })
  }

  catch (error) {
    console.log('Error getting grades', error);
  }
}

// Get grade for the contents of the current assignment with useOneRubric selected
export const getOneRubricGrades = async (oneRubricObj) => {
  const { studentId, assignmentId, courseId, setCurrentGrading } = oneRubricObj;
  try {
    const docName = `${studentId}-${assignmentId}`;
    const gradeRef = doc(db, 'grades', courseId, 'courseGrades', docName);
    onSnapshot(gradeRef, (doc) => {
      setCurrentGrading({ ...doc.data(), id: doc.id });
    })
  }

  catch (error) {
    console.log('Error getting one rubric grades', error);
  }
}
















































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      USER API
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------





// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------

export const updateUser = async (userObj) => {
  const { userId, payload } = userObj;
  console.log('userObj', userObj);
  try {
    // add to user document
    const userRef = doc(db, 'users', userId);
    const userPayload = payload;
    await setDoc(userRef, userPayload, { merge: true });

    return {status:'succes'};

  }

  catch (error) {
    return {status:'error'};
  }
}



// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------






// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

export const getStudent = async (studentObj) => {
  const { studentId, setStudent } = studentObj;
  try {
    const docRef = doc(db, 'users', studentId);
    onSnapshot(docRef, (doc) => {
      setStudent({...doc.data(), id: doc.id})
    })
    return {status: 'success'}
    
  } catch (error) {
    console.log(`Error on getting student doc`, error);
    return {status: 'error'}
  }
}


// Get all users 
export const useGetUsers = () => {
  const [users, setUsers] = useState();

  const getUsers = () => {
    try {
      const collectionRef = collection(db, 'users');
      onSnapshot(collectionRef, (snapshot) => {
        // Get all user instances from all classes with the current teacher
        const initUsers = snapshot.docs.map((doc) => ( { ...doc.data(), id: doc.id } ));
        setUsers(initUsers);

      })
    } catch (error) {
      console.log('Error fetching users', error)
    }
  }

  useEffect(() => {
    getUsers()
  }, [])

  return users ? users : null
}
















































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      FEEDBACK API
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------

export const createFeedback = async (feedbackObj) => {
  const { payload } = feedbackObj;
  try {
    const collectionRef = collection(db, 'feedback');
    const newFeedback = await addDoc(collectionRef, payload);
    return {status:'success', id: newFeedback.id};

  }

  catch (error) {
    return {status:'error'};
  }
}



// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------





// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------






// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

















































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      COURSE STUDENTS API
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------

export const getStudentsOfCoursesByTeacher = (studentsObj) => {
  const { teacherId, setStudents } = studentsObj;
  try {
    const collectionRef = collectionGroup(db, 'courseStudents');
    const q = query(collectionRef, where('teacher', '==', teacherId));
    onSnapshot(q, (snapshot) => {
      setStudents(snapshot.docs.map((doc) => ( { ...doc.data()} )));
    })
  } catch (error) {
    console.log('Error fetching students', error)
  }
}




// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------





// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------






// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

// Get all students enrolled with a particular teacher from courseStudents collections
export const useGetStudentsByTeacher = (studentsObj) => {
  const { teacherId } = studentsObj;
  const [students, setStudents] = useState();

  const getStudents = () => {
    try {
      const collectionRef = collectionGroup(db, 'courseStudents');
      const q = query(collectionRef, where('teacher', '==', teacherId));
      onSnapshot(q, (snapshot) => {
        // Get all student instances from all classes with the current teacher
        const initStudents = snapshot.docs.map((doc) => ( { ...doc.data(), id: doc.id } ));

        // Make sure students only appear once if they are in multiple classes
        const reducedStudentArr = initStudents.filter((student, index, array) => array.findIndex(t => t.id == student.id) == index);

        setStudents(reducedStudentArr);

      })
    } catch (error) {
      console.log('Error fetching students', error)
    }
  }

  useEffect(() => {
    getStudents()
  }, [])

  return students ? students : null
}


















































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      EVENTS API
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================




  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------

// Creates new teacher in events
export const createEventSubmission = async (obj) => {
  try {

    const submissionRef = doc(db, 'event-district', obj.email);
    const submissionDoc = await getDoc(submissionRef);

    if (submissionDoc.exists()) {
      return {status:'Submission already exists', data:submissionDoc.data()};
    } else {
      const subissionRef = doc(db, 'event-district', obj.email);
      const subissionPayload = {
        ...obj,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      }

      const newsubission = await setDoc(subissionRef, subissionPayload);
      return {status: "Successful Submission"};
    }

  }

  catch (error) {
      console.log('Error adding event subission', error);
  }
}

// Creates new teacher in events
export const createEventQuickJoinSubmission = async (obj) => {
  try {

    const submissionRef = doc(db, 'event-quick-join', obj.email);
    const submissionDoc = await getDoc(submissionRef);

    if (submissionDoc.exists()) {
      return {status:'Submission already exists', ...submissionDoc.data()};
    } else {
      const subissionRef = doc(db, 'event-quick-join', obj.email);
      const subissionPayload = {
        ...obj,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      }

      const newsubission = await setDoc(subissionRef, subissionPayload);
      return {...obj, status: "Successful Submission"};
    }

  }

  catch (error) {
      console.log('Error adding event subission', error);
  }
}




// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

// GET DATA DISTRICT LIST HOOK
export const useGetEventDistrictSubmission = () => {
  const [submissions, setSubmissions] = useState();

  const getEventDistrictSubmission = async () => {
    try {
      const submissionRef = doc(db, 'event-district');
      const submissionDoc = await getDoc(submissionRef);
      if (submissionDoc.exists()) {
        return submissionDoc.data();
      } else {
        return null;
      }
    } catch (error) {
      console.log('Error getting submissions', error);
    }
  }

  useEffect(() => {
    getEventDistrictSubmission()
  }, [])

  return submissions ? submissions : null
}













































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      DATA API
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------





// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------

// Creates new teacher in events
export const updateDataAdmin = async (obj) => {
  try {
      const dataAdminRef = doc(db, 'data', 'admin');
      const dataAdminPayload = {
        ...obj,
        updatedAt: serverTimestamp()
      }

      const newDataAdmin = await setDoc(dataAdminRef, dataAdminPayload);

  }

  catch (error) {
      console.log('Error adding data admin item', error);
  }
}




// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

// GET DATA DISTRICT LIST HOOK
export const useGetDataDistricts = () => {
  const [districts, setDistricts] = useState();

  const getDataDistricts = async () => {
    try {
      const dataAdminRef = doc(db, 'data', 'admin');
      onSnapshot(dataAdminRef, (doc) => {
          setDistricts(doc.data().districts)        
      })
    } catch (error) {
      console.log('Error getting districts', error);
    }
  }

  useEffect(() => {
      getDataDistricts()
  }, [])

  return districts ? districts : null
}

// GET DATA DISTRICT LIST
export const getDataDistricts = async () => {
  try {
    const dataAdminRef = doc(db, 'data', 'admin');
    const dataAdminSnap = await getDoc(dataAdminRef);
    if (dataAdminSnap.exists()) {
      return (dataAdminSnap.data().districtList);
    }
  } catch (error) {
    console.log('Error getting districts', error);
  }
}



















































// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================
//      RUBRIC LIBRARY API
// ==========================================================================================================================
// ==========================================================================================================================
// ==========================================================================================================================



  
// ------------------------------------------------------------------------------------
// CREATE OPERATIONS
// ------------------------------------------------------------------------------------

// Export rubric to teacher's rubric library
// obj = { rubric: assignment[rubricField], userId: authContext.account.id }
export const createRubric = async (obj) => {
  try {
    const docRef = collection(db, 'users', obj.userId, 'rubricLibrary');
    const payload = obj.payload;
    await addDoc(docRef, payload, {merge: true});
    return {status: 'success'}

  }

  catch (error) {
    console.log('Error submitting rubric', error);
    return {status: 'error'}
  }
}



// ------------------------------------------------------------------------------------
// UPDATE OPERATIONS
// ------------------------------------------------------------------------------------




// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

// DELETE rubric from library
// obj = { userId: authContext.account.id, rubricId: rubric.id }
export const deleteRubric = async (obj) => {
  try {
    await deleteDoc(doc(db, 'users', obj.userId, 'rubricLibrary', obj.rubricId));
    return "success"
  } catch (error) {
    console.log('Error deleting rubric from library', error)
    return "error"
  }

}





// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

export const readRubricLibrary = async (obj) => {
  try {
    const collectionRef = collection(db, 'users', obj.userId, 'rubricLibrary');
    onSnapshot(collectionRef, (snapshot) => {
      if (!snapshot.empty) {
        obj.set(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      }
    })
    return "success"

  } catch (error) {
    console.log('Error getting rubric library', error);
    return "error"
  }
}



